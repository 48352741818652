import { Alert, Box, Card, Link, Typography } from 'components/AORedesign';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import React, { useState } from 'react';

const QuickLinksForms = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadPdf = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return (
    <Card style={{ height: '100%' }}>
      <Box mb={2}>
        <Typography variant="h6">Common Forms/Requests</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && <Alert color="error" alertTitleMessage={downloadingPdfError} />}
      <Box mt={1}>
        <Link data-pdf="/e-forms/Policyholder EFT Authorization Form.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
          Customer EFT Enrollment
        </Link>
      </Box>
      <Box mt={1}>
        <Link data-pdf="/news/Statement_of_No_Loss.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
          Statement of No Loss
        </Link>
      </Box>
      <Box mt={1}>
        <Link
          onClick={resetErrorVisibility}
          href="mailto:customer@msagroup.com?subject=Report a Payment&body=Please provide the required information below to report a payment received in your office.  A confirmation email will be sent to you.%0A%0APolicy number:%0AAccount number:%0ANamed insured:%0AAmount of payment:%0ACheck number:%0A%0ATo learn more about Main Street America Group's payment options, please visit the Billing Inquiry and Payments tab."
          endIcon="mail">
          Report a Payment
        </Link>
      </Box>
    </Card>
  );
};

export default QuickLinksForms;
