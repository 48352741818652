import axios from 'axios';
import { Icon } from 'components/AORedesign/Icon';
import { GET_DASHBOARD_UNSOLD_QUOTES, GET_DASHBOARD_UNSOLD_QUOTES_STATUS } from 'constants/api';
import { ROUTE_QUOTES } from 'constants/routes';
import agentContext from 'contexts/agent.context';
import AppDataContext from 'contexts/appData.context';
import { usePolling } from 'hooks/usePolling';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DashboardActionCard from './DashboardActionCard';

const UNSOLD_QUOTES_KEY = 'unsold_quotes';
const STATUS_POLLING_MILLSECONDS = 5000;

const QuotesCard = () => {
  const history = useHistory();
  const { Locations } = useContext(agentContext);
  const { dashboard, updateDashboardCounts } = useContext(AppDataContext);
  const [correlationId, setCorrelationId] = useState();

  // create a pointer for ease of reference
  const unsoldQuotesObj = dashboard?.[UNSOLD_QUOTES_KEY];

  // check status of step function
  const [, setIsPolling] = usePolling(
    async () => {
      console.log(`[QuotesCard] POLLING - correlationId: ${correlationId}`);
      if (correlationId) {
        const response = await axios.get(`${GET_DASHBOARD_UNSOLD_QUOTES_STATUS}/${correlationId}`);
        const status = response?.data?.status;
        if (status === 'SUCCEEDED') {
          updateDashboardCounts(UNSOLD_QUOTES_KEY, response?.data);
          setIsPolling(false); // stop polling
          console.log(`[QuotesCard] POLLING SUCCEEDED - correlationId: ${correlationId}`);
        } else if (status === 'FAILED') {
          console.log(`[QuotesCard] POLLING FAILED - Step Function failed. correlationId: ${correlationId}`);
          setIsPolling(false); // stop polling
        } else {
          console.log(`[QuotesCard] POLLING RUNNING - correlationId: ${correlationId}`);
        }
      }
    },
    [correlationId],
    { interval: STATUS_POLLING_MILLSECONDS },
  );

  // on page load
  useEffect(async () => {
    // determine if this is a "large agency" (>6 locations)
    const largeAgencyNumLocations = dashboard?.locationCodeLimit || 6;
    const isLargeAgency = Locations.length > largeAgencyNumLocations;
    console.log(`[QuotesCard] isLargeAgency: ${isLargeAgency}`);
    // call the step function if:
    // - we aren't already polling for the result
    // - we don't already have the Unsold Quotes count
    // - the user is not part of a large agency
    if (!correlationId && !unsoldQuotesObj?.count && !isLargeAgency) {
      const response = await axios.get(GET_DASHBOARD_UNSOLD_QUOTES);
      const corrId = response?.data?.correlationId;
      setCorrelationId(corrId);
      setIsPolling(true); // start polling for a response
    }
  }, [correlationId, unsoldQuotesObj, Locations]);

  const handleCardClick = () => {
    history.push(`${ROUTE_QUOTES}?initialTab=CL&navSource=unsoldQuotesCard`);
  };

  return (
    <DashboardActionCard
      count={unsoldQuotesObj?.count}
      icon={<Icon iconName="icons-icon-request-quote" />}
      onClick={() => handleCardClick()}
      subtitle="LAST 30 DAYS"
      title="Unsold Quotes"
    />
  );
};

export default QuotesCard;
