import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState } from 'react';

import useScreenDefinition from '../../hooks/useScreenDefinition';
import { CardTabSkeleton } from '../AOSkeleton';
import { ScreenSmith } from '../ScreenSmith';

import PLSMaAutoDecReprint from './MaAutoDecReprint/PLSMaAutoDecReprint';
import PLSNews from './PLSNews';
import PLSPolicyChangeView from './PLSPolicyChangeView';
import PLSPolicyDocuments from './PolicyDocuments/PLSPolicyDocuments';

import { handleTabChange } from '../CommonUtilityServices/EventHandlerService';
import { redirectUrlFunction } from '../CommonUtilityServices/RedirectService';
import { NewsArticles } from '../NewsArticles';

import { StyledPersonalLinesServices } from './PersonalLinesServices.styled';

import { handleRouteAndOpenInNewWindow, plPolicyChangeViewSearchTypeMenuItemsData } from './PersonalLinesServices.api';

const PanelComponents = {
  PLSNews,
  PLSPolicyChangeView,
  PLSPolicyDocuments,
  PLSMaAutoDecReprint,
  NewsArticles,
};

const PersonalLinesServices = () => {
  // Screen def
  const { UiDefinitionsData, loadingSd } = useScreenDefinition('PLSHome');
  const [isPlsRedirectErrorVisible, setIsPlsRedirectErrorVisible] = useState(false);
  const [plsRedirectError, setPlsRedirectError] = useState(null);

  const plsNewBusinessQuickLink = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'New Business',
      DetailedContext: [],
    };
    redirect(request_body);
  };

  const plsNewQuoteQuickLink = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Quick Quote',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const plsMainStreetStationQuickLink = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Main Street Station',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const plsUmbrellaQuickQuoteLink = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Umbrella Quick Quote',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const plsMainStreetPersonalPlusQuickLink = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'MSP+',
      DetailedContext: [
        {
          name: 'effectiveDate',
          value: moment().format('MM/DD/yyyy'),
        },
      ],
    };
    return redirect(request_body);
  };

  const personalLinesPcr = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'PCR',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const underwritingCloud = () => {
    resetError();
    const request_body = {
      Area: 'Personal Lines',
      TypeOfRedirect: 'Coastal Guidelines',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const redirect = (request_body) => {
    return redirectUrlFunction(request_body, 'POST', null, setPlsRedirectError, setIsPlsRedirectErrorVisible);
  };

  const resetError = () => {
    setPlsRedirectError(null);
    setIsPlsRedirectErrorVisible(false);
  };

  const [plPolicyChangeViewSearchTypeMenuItems] = useState(plPolicyChangeViewSearchTypeMenuItemsData);

  const [tabValue, setTabValue] = React.useState(1);

  // Policy Document State
  const [plPolicyDocumentsColData] = useState([
    {
      field: 'documentType',
      headerName: 'Document Type',
      width: `25%`,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: true,
      flex: 2,
    },
    {
      field: 'documentDate',
      headerName: 'Date',
      type: 'date',
      sortable: true,
      flex: 1,
    },
    {
      field: 'documentLink',
      headerName: 'Document Link',
      sortable: true,
      flex: 1,
      //renderCell, // renderCell : renderCell which is function to render custom content to download pdf
    },
  ]);
  const [plPolicyDocumentNumber, setPlPolicyDocumentNumber] = useState('');

  // --- Event Handlers for PL Policy Documents ---
  const handleChangePlPolicyDocumentNumber = useCallback((event) => {
    setPlPolicyDocumentNumber(event.target.value);
  });

  const plState = {
    plPolicyChangeViewSearchTypeMenuItems,
    plPolicyDocumentNumber,
    plPolicyDocumentsColData,
    tabValue,
    isPlsRedirectErrorVisible,
    plsRedirectError,
  };

  const callBackFunctions = {
    handleChangePlPolicyDocumentNumber,
    handleRouteAndOpenInNewWindow,
    handleTabChange: (e, newValue) => handleTabChange(e, newValue, setTabValue),
    personalLinesPcr,
    plsMainStreetPersonalPlusQuickLink,
    plsMainStreetStationQuickLink,
    plsNewBusinessQuickLink,
    plsNewQuoteQuickLink,
    underwritingCloud,
    plsUmbrellaQuickQuoteLink,
  };

  if (loadingSd) {
    return <CardTabSkeleton />;
  }

  return (
    <StyledPersonalLinesServices>
      {!_.isNil(UiDefinitionsData) && (
        <ScreenSmith definition={UiDefinitionsData} componentMap={PanelComponents} functionMap={callBackFunctions} stateMap={plState} />
      )}
    </StyledPersonalLinesServices>
  );
};

export default PersonalLinesServices;
