import { Alert, Box, Link, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import { MAILING_AND_CONTACT_INFORMATION_LINK } from 'constants/static';
import AccountAdminContext from 'contexts/accountAdmin.context';
import React, { useContext, useState } from 'react';
import AccountAdminSkeleton from './AccountAdminSkeleton';

const ReferenceMaterial = () => {
  const { accountAdminHasData } = useContext(AccountAdminContext);
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadPdf = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
  };

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const _fileUrl = event.currentTarget.getAttribute('data-file');
    handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
  };

  const resetErrorVisibility = () => {
    setIsDownloadingPdfErrorVisible(false);
  };

  return !accountAdminHasData ? (
    <AccountAdminSkeleton />
  ) : (
    <Box mb={4}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Reference Material</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && <Alert color="error" alertTitleMessage={downloadingPdfError} />}
      <Box mt={2} mb={4}>
        <Box mb={2}>
          <Typography variant="button" display="block" gutterBottom>
            AGENT FORMS AND REQUESTS
          </Typography>
        </Box>
        <Feature allowed={featureFlags.ACCESS_AGENCY_COMPENSATION_SCHEDULES}>
          <Box mt={1}>
            <Link href="https://brandfolder.com/s/h82tfgwftcjzq6gbrzhznmjw" endIcon="openInNew">
              Agency Compensation Schedules
            </Link>
          </Box>
        </Feature>
        <Box mt={1}>
          <Link data-pdf="/agency-automation/Agency EFT Commission Deposit Form.pdf" onClick={handleClickDownloadPdf} endIcon="pdf">
            Electronic Commission & Agency Sweep Authorization Form
          </Link>
        </Box>
        <Box mt={1}>
          <Link
            onClick={resetErrorVisibility}
            href="mailto:ITSupportServices@msagroup.com?subject=Download/Retransmit&body=Please provide the required information below to request a download or retransmission of a policy.%0A%0ANamed Insured:%0APolicy number:%0AAgency code:%0ATransaction Type:%0AEffective Date of Download:%0AReason for Download Retransmit:%0A%0ABe advised that items transmitted after Jan. 8, 2018 can be obtained via self-service at your agency's IVANS Exchange.%0A%0APlease note that only the last transaction processed on a policy can be retransmitted.%0A%0ADid you know the IVANS Exchange website offers agents the ability to log-in and self-serve all of your download needs? IVANS can assist you with logging into IVANS Exchange and provide instructions on how to retrieve Main Street America downloads since our conversion on Jan. 8, 2018.  The IVANS Exchange Help Desk can be reached at support@ivansinsurance.com"
            endIcon="mail">
            Download/Retransmit Requests
          </Link>
        </Box>
        <Box mt={1}>
          <Link data-file="/agency-automation/Change%20of%20Agency%20Information%20Request.doc" onClick={handleClickDownloadFile} endIcon="file">
            Change of Agency Information Request
          </Link>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <Typography variant="button" display="block" gutterBottom>
          CONTACT INFORMATION
        </Typography>
      </Box>
      <Box mt={1}>
        <Link data-pdf={'/news/flier_MSACS.pdf'} onClick={handleClickDownloadPdf} endIcon="pdf">
          Customer Services Overview
        </Link>
      </Box>
      <Box mt={1}>
        <Link data-pdf={'/news/CustServQuickRef%20C.pdf'} onClick={handleClickDownloadPdf} endIcon="pdf">
          Customer Services Phone Line Quick Reference
        </Link>
      </Box>
      <Box mt={1}>
        <Link href={MAILING_AND_CONTACT_INFORMATION_LINK} endIcon="openInNew">
          Mailing & Contact Information
        </Link>
      </Box>
    </Box>
  );
};

export default ReferenceMaterial;
