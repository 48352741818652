import { Alert, Box, Card, Link, Typography } from 'components/AORedesign';
import { redirectUrlFunction } from 'components/CommonUtilityServices/RedirectService';
import { farmRanchRedirect } from 'components/FarmRanch';
import { Feature } from 'components/Feature';
import featureFlags from 'constants/featureFlags';
import React, { useState } from 'react';

const QuickLinksTools = () => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const redirect = (request_body) => {
    return redirectUrlFunction(request_body, 'POST', null, setErrorMessage, setIsErrorVisible);
  };

  const pAustinMutualQuickLink = () => {
    const request_body = {
      Area: 'Austin Mutual',
      TypeOfRedirect: 'Login',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  const pSuretyBondsQuickLink = () => {
    const request_body = {
      Area: 'Policy Services Menu',
      TypeOfRedirect: 'Surety and Fidelity Bonds',
      DetailedContext: [],
    };
    return redirect(request_body);
  };

  return (
    <Card style={{ height: '100%' }}>
      <Box mb={2}>
        <Typography variant="h6">Tools</Typography>
      </Box>
      {isErrorVisible && <Alert color="error" alertTitleMessage={errorMessage} />}
      <Box mt={1}>
        <Link onClick={pAustinMutualQuickLink} endIcon="openInNew">
          Austin Mutual Agency
        </Link>
      </Box>
      <Box mt={1}>
        <Link onClick={pSuretyBondsQuickLink} endIcon="openInNew">
          Surety Bonds
        </Link>
      </Box>
      <Box mt={1}>
        <Link href="https://www.msainsurance.com/claims" endIcon="openInNew">
          Report a Claim
        </Link>
      </Box>
      <Feature allowed={[featureFlags.ACCESS_FARM_RANCH]}>
        <Box mt={1}>
          <Link onClick={() => farmRanchRedirect(setErrorMessage, setIsErrorVisible)} endIcon="openInNew">
            Farm and Ranch Quotes
          </Link>
        </Box>
      </Feature>
      <Box mt={1}>
        <Link
          href="https://cloud.mail.msagroup.com/renewal-transition?utm_source=agentsonly&utm_medium=web&utm_campaign=renewal_transition "
          endIcon="openInNew">
          Renewal Transition Information
        </Link>
      </Box>
    </Card>
  );
};

export default QuickLinksTools;
