import React, { useState } from 'react';

import { Alert, Box, Link, Typography } from 'components/AORedesign';
import { handleFileDownload } from 'components/CommonUtilityServices/DownloadFileService';
import { handlePdfDownload } from 'components/CommonUtilityServices/DownloadPdfService';
import LinksContainer from './LinksContainer';

const PDF_ICON = 'pdf';
const NEW_WINDOW_ICON = 'openInNew';

const ClientFormRequests = () => {
  const [isDownloadingPdfErrorVisible, setIsDownloadingPdfErrorVisible] = useState(false);
  const [downloadingPdfError, setDownloadingPdfError] = useState('');

  const handleClickDownloadFile = (event) => {
    setIsDownloadingPdfErrorVisible(false);
    const pdfUrl = event.currentTarget.getAttribute('data-pdf');
    if (pdfUrl) {
      handlePdfDownload('GET', null, pdfUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible, 'newWindow');
    } else {
      const _fileUrl = event.currentTarget.getAttribute('data-file');
      handleFileDownload(_fileUrl, () => {}, setDownloadingPdfError, setIsDownloadingPdfErrorVisible);
    }
  };

  return (
    <Box mb={2}>
      <Box mt={1} mb={4}>
        <Typography variant="h6">Forms & Requests for Your Clients</Typography>
      </Box>
      {isDownloadingPdfErrorVisible && (
        <Box mb={2}>
          <Alert color="error" alertTitleMessage={downloadingPdfError} />
        </Box>
      )}
      <Box>
        <LinksContainer heading="FORMS">
          <Link data-pdf={'/e-forms/Policyholder%20EFT%20Authorization%20Form.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Policyholder EFT Enrollment Form
          </Link>
          <Link data-pdf={'/news/Statement_of_No_Loss.pdf'} onClick={handleClickDownloadFile} endIcon={PDF_ICON}>
            Statement of No Loss Form
          </Link>
        </LinksContainer>
        <LinksContainer heading="Renewal Transition">
          <Link
            href="https://cloud.mail.msagroup.com/renewal-transition?utm_source=agentsonly&utm_medium=web&utm_campaign=renewal_transition"
            endIcon={NEW_WINDOW_ICON}>
            Renewal Transition Information
          </Link>
          <Link href="https://brandfolder.com/s/7cjv3wsnnprqkqpkq9jcbbtm" endIcon={PDF_ICON}>
            Renewal Transition Toolkit
          </Link>
        </LinksContainer>
      </Box>
    </Box>
  );
};

export default ClientFormRequests;
