import React, { useContext, useEffect } from 'react';
import AccountAdminContext from 'contexts/accountAdmin.context';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { Link, Typography, Box } from 'components/AORedesign';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { GET_AGENCY_HAS_MSA_ACCESS } from 'constants/api';
import { ROUTE_ACCOUNT_ADMIN_REGISTRATION_MATERIALS } from 'constants/routes';
import AccountAdminSkeleton from './AccountAdminSkeleton';
import { SALES_AND_SERVICE_CENTER_INFO_REQUEST } from 'constants/emails';

const MsaServices = () => {
  const { msaServicesData, setMsaServicesData, accountAdminHasData } = useContext(AccountAdminContext);
  const [{ data, loading, error }] = useAxios(GET_AGENCY_HAS_MSA_ACCESS, { manual: !_.isEmpty(msaServicesData) });

  const history = useHistory();

  const handleGoToRoute = (e) => {
    const route = e.currentTarget.getAttribute('data-route');
    history.push(route);
  };

  useEffect(() => {
    if (!loading && data) {
      setMsaServicesData(data);
    }
    if (error) {
      console.error(error);
      setMsaServicesData({});
    }
  }, [loading, error]);

  return !accountAdminHasData ? (
    <AccountAdminSkeleton />
  ) : (
    <Box mb={3}>
      <Box mb={2}>
        <Box mt={1} mb={4}>
          <Typography variant="h6">Services</Typography>
        </Box>
      </Box>
      <Box mb={2}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Box mt={1} fontWeight={'bold'}>
              Services
            </Box>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={1}>
            <Box mt={1} ml={3} fontWeight={'bold'}>
              Enrolled
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Box mt={2}>
            <Typography>Download</Typography>
          </Box>
          <Box mt={1} style={{ color: 'grey' }}>
            Download is a service that will send Agents policy information from Main Street America to their Agency Management Systems for servicing.
          </Box>
          <Box mt={1} mb={2}>
            <Link data-route={ROUTE_ACCOUNT_ADMIN_REGISTRATION_MATERIALS} onClick={handleGoToRoute}>
              Registration Materials
            </Link>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box mt={6} ml={3}>
            {msaServicesData?.hasEdmAccess && <CheckCircleOutline style={{ color: 'green' }} />}
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Box mt={2}>
            <Typography>MSA Service Center</Typography>
          </Box>
          <Box mt={1} style={{ color: 'grey' }}>
            Have our trained insurance professionals manage the daily servicing of your accounts, giving you the increased capacity to grow your business.
          </Box>
          <Box mt={1}>
            <Link href={'https://brandfolder.com/s/pzcwhqhvqw8xtwn86nhtxvh'} endIcon="openInNew">
              Learn More
            </Link>
          </Box>
          <Box mt={1}>
            <Link
                href={SALES_AND_SERVICE_CENTER_INFO_REQUEST}
                endIcon="mail">
                Sales & Service Center Info Request
              </Link>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box mt={6} ml={3}>
            {msaServicesData?.hasSvcCtrAccess && <CheckCircleOutline style={{ color: 'green' }} />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MsaServices;
