import MuiFormControl from '@material-ui/core/FormControl';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiSelect from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';

import { componentIdStringGenerator } from '../../../helpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: `${rem(280)}`,

    [theme.breakpoints.down('sm')]: {
      minWidth: `${rem(250)}`,
    },
  },
}));

const Select = ({ menuItems, ...props }) => {
  const classes = useStyles();

  const labelId = componentIdStringGenerator('Select-Label', props.id ? props.id : props.label ?? '');

  return (
    <MuiFormControl variant="outlined" className={classes.formControl}>
      <MuiInputLabel id={labelId}>{props.label}</MuiInputLabel>
      <MuiSelect {...props} id={componentIdStringGenerator('Select', props.id ? props.id : props.label ?? '')} labelId={labelId}>
        {menuItems?.map((menuItem) => (
          <MuiMenuItem
            key={`${menuItem.value}-${menuItem.label}`}
            id={componentIdStringGenerator('Select-Item', menuItem.value)}
            disabled={menuItem.disabled}
            value={menuItem.value}>
            {menuItem.label}
          </MuiMenuItem>
        ))}
      </MuiSelect>
    </MuiFormControl>
  );
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
};

export default Select;
